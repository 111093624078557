import { Button, Drawer, IconButton, Typography } from "@material-tailwind/react"
import React from "react"
import { answerCheckAtom } from "../../utils/atoms"
import { useAtom } from "jotai"


type BottomDrawerProps = {
  children?: React.ReactNode
  open: boolean
  onClose?: () => void
  title?: React.ReactNode
  isTrue?: boolean
  description?: string
}


const BottomDrawer = ({
}: BottomDrawerProps) => {
  const [openBottom, setOpenBottom] = useAtom(answerCheckAtom);
  const openDrawerBottom = () => setOpenBottom(true);
  // const closeDrawerBottom = () => setOpenBottom(false);

  let buttonTrue = `bg-[#61E000]   border-b-4 mt-6 mb-2 text-white font-bold py-2 px-4 rounded-xl    border-[#58A700]`
  let buttonFalse = `bg-[#FF5354]   border-b-4 mt-6 mb-2 text-white font-bold py-2 px-4 rounded-xl    border-[#EA2B2B]`
  return (
    <Drawer
      placement="bottom"
      open={Boolean(openBottom)} style={{ marginBottom: 10, }}
      // onClose={closeDrawerBottom}
      className={`p-4 ${openBottom?.isTrue ? 'bg-[#D7FFB8]' : 'bg-[#FFDFDF]'}
       
      items-center justify-center text-center 
       
       grid

      `}
    >
      <div className="flex flex-row place-items-center max-w-screen-md" style={{ fontFamily: 'poppin-regular' }}>


        <div className="" >

          {
            openBottom?.isTrue ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4CAF50"
                className="w-[40px] h-[40px] md:w-[70px] md:h-[70px]">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>

              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#EA2B2B"
                className="w-[70px] h-[70px]">
                <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>

          }
        </div>
        <div className="flex flex-col m-2 md:m-8 ml-2 md:ml-8  " >

          <Typography style={{ color: openBottom?.isTrue ? '#58A700' : "#EA2B2B", fontFamily: 'poppin-medium' }}
            className="text-left text-lg md:text-xl lg:text-3xl xl:text-4xl"
          >
            {openBottom?.isTrue ? "Great, correct answer:" : "Correct answer:"}
          </Typography>
          <div className="">
            <Typography variant="paragraph" style={{ color: openBottom?.isTrue ? '#58A700' : "#EA2B2B", fontFamily: 'poppin-regular' }}
              className="text-left text-sm md:text-base lg:text-lg xl:text-xl"
            >
              {openBottom?.description}
            </Typography>
          </div>
        </div>

        <div className="grid place-items-center">
          {
            window.innerWidth >= 600 &&
            <button className={openBottom?.isTrue ? buttonTrue : buttonFalse}
              onClick={openBottom?.onClick}
            >
              Continue
            </button>

          }
        </div>
      </div>
      {window.innerWidth < 600 &&
        <button className={openBottom?.isTrue ? buttonTrue : buttonFalse}
          onClick={openBottom?.onClick}
        >
          Continue
        </button>}
    </Drawer>
  )
}
export default BottomDrawer;