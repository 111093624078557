import React, { useEffect, useState } from "react";
import SpotQuestionCheckboxWrapper from "../SpotQuestionCheckboxWrapper/SpotQuestionCheckboxWrapper";
import Checkbox from "../Checkboxes/Checkboxes";
import { Alert, Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography, } from "@material-tailwind/react";
import { useAtom, useAtomValue } from "jotai";
import { answerCheckAtom, defaultQuestions, questionFormAtom, wrongAnswersAtom } from "../../utils/atoms";
import ProgressBar from "@ramonak/react-progress-bar";
import useSound from "use-sound";
import trueSound from '../../assets/sound/true.mp3'
import falseSound from '../../assets/sound/false.mp3';


type QuestionCardProps = {


  handleSelect?: Function
  currentPage?: number
  hasNextPage?: boolean
}

const QuestionCard: React.FC<QuestionCardProps> = ({

  handleSelect,
  hasNextPage
}: QuestionCardProps) => {


  let path = window.location.pathname;
  let currentPage = parseInt(path.split('/')[2])
  const [selected, setSelected] = React.useState<string>()
  // const [alert, setAlert] = React.useState<any>(false)
  const [questionForm, setQuestionForm] = useAtom(questionFormAtom)
  const [, setWrongAnswers] = useAtom(wrongAnswersAtom)
  const [, setAnswerCheck] = useAtom(answerCheckAtom)
  const [currentQuestion, setCurrentQuestion] = useState<any>({})
  const [disabled, setDisabled] = useState<boolean>(false)
  const [playTrueSound] = useSound(trueSound)
  const [playFalseSound] = useSound(falseSound)
  const [openCase, setOpenCase] = useState<boolean>(false)
  const [openBoard, setOpenBoard] = useState<boolean>(false)
  const defaultquestions = useAtomValue(defaultQuestions)

  useEffect(() => {
    setCurrentQuestion(questionForm[currentPage])
    questionForm.find((element: any) => { //!!!!!!!!!! DUZELT BUNU ISIN BITINCE
      if (questionForm[currentPage]._id === element._id) {
        if (element.types[0] === 'case') {
          setOpenCase(true)
        }
      }
      if (questionForm[currentPage]._id === element._id) {
        if (element.types[0] === 'board') {
          setOpenBoard(true)
        }
      }
    })
  }, [])

  function handleChangeSelected(id: string) {
    setQuestionForm((prev: any) => {
      let newArray = prev
      newArray[currentPage].answer = id
      return newArray
    })
    setSelected(id)
    setDisabled(true)
  }

  // useEffect(() => {
  //   console.log('selectedChick', selected);
  //   console.log('questionForm[currentPage].answer', questionForm[currentPage]);

  // }, [selected])
  function openDrawer() {
    // setIsLoading(true)
    let correctAnswer = currentQuestion.chicks.find((element: any) => {
      return element.isTrue ? element : null
    });
    let selectedChick = questionForm[currentPage].chicks.find((element: any) => element._id === selected)
    // let selectedChick = questionForm[currentPage].chicks.find((element: any) => element._id === questionForm[currentPage].answer)




    const isCorrectAnswer = correctAnswer._id === selected
    if (isCorrectAnswer) {
      playTrueSound()
    }
    else {
      playFalseSound()
    }
    setAnswerCheck({
      open: true,
      isTrue: isCorrectAnswer,
      description: selectedChick.description,
      onClick: () => {
        handleNext()
      }
    })
  }
  function handleNext() {
    let correctAnswer = currentQuestion.chicks.find((element: any) => {
      return element.isTrue ? element : null
    });
    const isCorrectAnswer = correctAnswer._id === selected
    if (isCorrectAnswer) {
    }
    else {
      if (currentQuestion?.types?.[0] === 'spot') {
        let list = [...questionForm]
        let willingIndex = list.findIndex((element: any) => element?.types?.[0] === 'case')
        list.splice(willingIndex, 0, { ...currentQuestion, answer: "" })
        setQuestionForm(list)
      }
      setWrongAnswers((prev: any) => [...prev, currentQuestion])
    }

    if (hasNextPage) {
      window.location.href = `/form/${currentPage + 1}`
    }
    else {
      submit()
      // window.location.href = `/survey`
    }

  }
  function submit() {

    let answers: any = []
    questionForm.forEach((element: any) => {
      answers.push({
        question_id: element?._id,
        answer_id: element?.answer,
        chicks: element?.chicks,
        types: element?.types
      })
    });
    console.log('questionForm', answers);

    let scoreCount = 0
    for (let index = 0; index < defaultquestions?.length; index++) {

      const trueanswerid = defaultquestions[index].chicks.find((chick: any) => {
        return chick.isTrue ? chick._id : null
      })._id
      console.log('trueanswerid', trueanswerid, trueanswerid === questionForm[index].answer);

      if (trueanswerid === questionForm[index].answer) {
        scoreCount += 1
      }
    }

    let body = {
      answers,
      score: scoreCount
    }



    fetch(process.env.REACT_APP_IP + 'survey/create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        console.log('datalo', data);

        if (data) {
          localStorage.setItem('questions', JSON.stringify([]))
          localStorage.setItem('wronganswers', JSON.stringify([]))
          localStorage.setItem('user', JSON.stringify(data))
          // window.location.href = `https://genius99.co/thank-you`
          window.open(`https://form.typeform.com/to/yTgoEKIk#score=${scoreCount}`, "_self")
          // https://genius99.com/thank-you?score=${scoreCount}&questionlength=${defaultquestions.length}
        }
        else {
          alert('Survey not submitted!')
        }
      })
      .catch(err => {
        console.log(err);

        // alert(err.message)
      })
  }

  let completed = ((currentPage + 1) * 100 / (questionForm.length)).toFixed(0)

  return (

    <div

      style={{
        width: window.innerWidth > 600 ? 700 : window.innerWidth,
        justifyContent: 'center',
      }} >


      <div style={{
        height: window.innerWidth < 650 ? window.innerHeight : '100%',
        justifyContent: 'center',
      }}
      >
        <div style={{ borderWidth: 1, marginBottom: '1.5rem' }} >
          <ProgressBar customLabelStyles={{ height: 5, marginBottom: 10 }} bgColor="#7F83F7"
            completed={completed} isLabelVisible={false} height="15px"
            width="100%"
            className="" />
        </div>
        <div
          className="
          flex 
          p-8 
          sm:min-w-screen-sm
          md: min-w-screen-md
           
          bg-white 
          rounded-xl
          w-full
          items-center 
          justify-center
          flex-col
          drop-shadow-xl
          "
        >
          <Typography
            style={{ fontFamily: 'poppin-light', }}
            className={`      
            font-bold text-left
              max-w-screen-md
              mb-4
              font-bold               
              text-sm
              sm:text-[1rem]
              md:text-[1.2rem]
              leading-7
          `
            }>
            {currentQuestion?.question}
          </Typography>
          {/* //! checkbox buttons */}
          {currentQuestion?.chicks?.map((item: any) => {
            return <Checkbox chick={item}
              key={item._id} id={item._id} text={item.text} type={currentQuestion?.type}
              selected={questionForm[currentPage].answer} onclick={handleChangeSelected} />
          })
          }
          <Checkbox id={'next'} disabled={!disabled}
            onclick={openDrawer}
            text="Next"
          />

        </div>
      </div >
      <div className="w-screen flex justify-center md:min-w-screen-md" />

      <CaseStudyDialog openCase={openCase} setOpenCase={setOpenCase} />
      <BoardQuestionDialog openBoard={openBoard} setOpenBoard={setOpenBoard} />
    </div>




  )
}
export default QuestionCard;


const CaseStudyDialog = ({ openCase, setOpenCase }: any) => {

  const handleOpen = () => setOpenCase(!openCase)
  return (
    <Dialog open={openCase} handler={handleOpen} className="grid place-items-center p-8">
      <DialogHeader className="self-center text-center"
        style={{ fontFamily: 'poppin-medium' }}
      >Great job on the Spot Questions!</DialogHeader>
      <DialogBody className="text-center"
        style={{
          fontFamily: 'poppin-regular', color: 'black',
          paddingLeft: 10, paddingRight: 10
        }} >
        Now, let's apply your knowledge to real-world scenarios. The Demo test!
      </DialogBody>
      <DialogFooter className="grid justify-center " >
        <Button variant="gradient" onClick={handleOpen} className="self-center"
          style={{
            fontFamily: 'poppin-medium',
            borderBottomWidth: 4,
            borderBottomColor: '#58A700',
            background: '#61E000'
          }}
        >
          <span>Continue</span>
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

const BoardQuestionDialog = ({ openBoard, setOpenBoard }: any) => {

  const handleOpen = () => setOpenBoard(!openBoard)
  return (
    <Dialog open={openBoard} handler={handleOpen} className="grid place-items-center">
      <DialogHeader className="self-center text-center"
        style={{ fontFamily: 'poppin-medium' }}
      >
        You've tackled real-world cases!
      </DialogHeader>
      <DialogBody className="text-center"
        style={{
          fontFamily: 'poppin-regular', color: 'black',
          paddingLeft: 10, paddingRight: 10
        }}
      >
        Up next: Board-Style Questions to prepare you for the USMLE format.
      </DialogBody>
      <DialogFooter className="grid justify-center " >
        <Button variant="gradient" color="green" onClick={handleOpen} className="self-center"
          style={{
            fontFamily: 'poppin-medium',
            borderBottomWidth: 4,
            borderBottomColor: '#58A700',
            background: '#61E000'
          }}
        >
          <span>Continue</span>
        </Button>
      </DialogFooter>
    </Dialog>
  )
}





const data = [
  {
    "question_id": "656f3d517c3eccddc7d9aec6",
    "answer_id": "656f3d867c3eccddc7d9aec9",
    "chicks": [
      {
        "_id": "656f3d867c3eccddc7d9aec8",
        "question_id": "656f3d517c3eccddc7d9aec6",
        "answer": "A) Posterior urethral valves\n",
        "description": "A\tPosterior urethral valves → Bilateral hydronephrosis and thickened bladder wall, most common cause of bladder outlet obstruction in male infants\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:11:02.962Z",
        "updatedAt": "2023-12-05T15:11:02.962Z"
      },
      {
        "_id": "656f3d867c3eccddc7d9aec9",
        "question_id": "656f3d517c3eccddc7d9aec6",
        "answer": "B) Fanconi syndrome\n",
        "description": "B\tFanconi syndrome → Reabsorption defect in PCT → metabolic acidosis, hypophosphatemia\n",
        "isTrue": true,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:11:02.962Z",
        "updatedAt": "2023-12-05T15:11:02.962Z"
      },
      {
        "_id": "656f3d867c3eccddc7d9aeca",
        "question_id": "656f3d517c3eccddc7d9aec6",
        "answer": "C) Bartter syndrome\n",
        "description": "C\tBartter syndrome → Reabsorption defect in thick ascending loop of Henle → metabolic alkalosis, hypercalciuria\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:11:02.962Z",
        "updatedAt": "2023-12-05T15:11:02.962Z"
      },
      {
        "_id": "656f3d867c3eccddc7d9aecb",
        "question_id": "656f3d517c3eccddc7d9aec6",
        "answer": "D) Gitelman syndrome\n",
        "description": "D\tGitelman syndrome → Reabsorption defect in DCT (NaCl) → metabolic alkalosis, hypocalciuria \n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:11:02.962Z",
        "updatedAt": "2023-12-05T15:11:02.962Z"
      },
      {
        "_id": "656f3d867c3eccddc7d9aecc",
        "question_id": "656f3d517c3eccddc7d9aec6",
        "answer": "E) Liddle syndrome\n",
        "description": "E\tLiddle syndrome → Reabsorption defect in collecting tubules → metabolic alkalosis, ↓ aldosterone\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:11:02.962Z",
        "updatedAt": "2023-12-05T15:11:02.962Z"
      }
    ],
    "types": [
      "spot"
    ]
  },
  {
    "question_id": "656f3d917c3eccddc7d9aece",
    "answer_id": "656f3dce7c3eccddc7d9aed2",
    "chicks": [
      {
        "_id": "656f3dce7c3eccddc7d9aed0",
        "question_id": "656f3d917c3eccddc7d9aece",
        "answer": "A) Posterior urethral valves\n",
        "description": "A\tPosterior urethral valves → Bilateral hydronephrosis and thickened bladder wall, most common cause of bladder outlet obstruction in male infants\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:12:14.923Z",
        "updatedAt": "2023-12-05T15:12:14.923Z"
      },
      {
        "_id": "656f3dce7c3eccddc7d9aed1",
        "question_id": "656f3d917c3eccddc7d9aece",
        "answer": "B) Fanconi syndrome\n",
        "description": "B\tFanconi syndrome → Reabsorption defect in PCT → metabolic acidosis, hypophosphatemia\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:12:14.924Z",
        "updatedAt": "2023-12-05T15:12:14.924Z"
      },
      {
        "_id": "656f3dce7c3eccddc7d9aed2",
        "question_id": "656f3d917c3eccddc7d9aece",
        "answer": "C) Bartter syndrome\n",
        "description": "C\tBartter syndrome → Reabsorption defect in thick ascending loop of Henle → metabolic alkalosis, hypercalciuria\n",
        "isTrue": true,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:12:14.924Z",
        "updatedAt": "2023-12-05T15:12:14.924Z"
      },
      {
        "_id": "656f3dce7c3eccddc7d9aed3",
        "question_id": "656f3d917c3eccddc7d9aece",
        "answer": "D) Gitelman syndrome\n",
        "description": "D\tGitelman syndrome → Reabsorption defect in DCT (NaCl) → metabolic alkalosis, hypocalciuria \n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:12:14.924Z",
        "updatedAt": "2023-12-05T15:12:14.924Z"
      },
      {
        "_id": "656f3dce7c3eccddc7d9aed4",
        "question_id": "656f3d917c3eccddc7d9aece",
        "answer": "E) Liddle syndrome\n",
        "description": "E\tLiddle syndrome → Reabsorption defect in collecting tubules → metabolic alkalosis, ↓ aldosterone\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:12:14.924Z",
        "updatedAt": "2023-12-05T15:12:14.924Z"
      }
    ],
    "types": [
      "spot"
    ]
  },
  {
    "question_id": "656f3ddb7c3eccddc7d9aed6",
    "answer_id": "656f3e087c3eccddc7d9aedb",
    "chicks": [
      {
        "_id": "656f3e087c3eccddc7d9aed8",
        "question_id": "656f3ddb7c3eccddc7d9aed6",
        "answer": "A) Posterior urethral valves\n",
        "description": "A\tPosterior urethral valves → Bilateral hydronephrosis and thickened bladder wall, most common cause of bladder outlet obstruction in male infants\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:13:12.575Z",
        "updatedAt": "2023-12-05T15:13:12.575Z"
      },
      {
        "_id": "656f3e087c3eccddc7d9aed9",
        "question_id": "656f3ddb7c3eccddc7d9aed6",
        "answer": "B) Fanconi syndrome\n",
        "description": "B\tFanconi syndrome → Reabsorption defect in PCT → metabolic acidosis, hypophosphatemia\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:13:12.576Z",
        "updatedAt": "2023-12-05T15:13:12.576Z"
      },
      {
        "_id": "656f3e087c3eccddc7d9aeda",
        "question_id": "656f3ddb7c3eccddc7d9aed6",
        "answer": "C) Bartter syndrome\n",
        "description": "C\tBartter syndrome → Reabsorption defect in thick ascending loop of Henle → metabolic alkalosis, hypercalciuria\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:13:12.576Z",
        "updatedAt": "2023-12-05T15:13:12.576Z"
      },
      {
        "_id": "656f3e087c3eccddc7d9aedb",
        "question_id": "656f3ddb7c3eccddc7d9aed6",
        "answer": "D) Gitelman syndrome\n",
        "description": "D\tGitelman syndrome → Reabsorption defect in DCT (NaCl) → metabolic alkalosis, hypocalciuria \n",
        "isTrue": true,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:13:12.576Z",
        "updatedAt": "2023-12-05T15:13:12.576Z"
      },
      {
        "_id": "656f3e087c3eccddc7d9aedc",
        "question_id": "656f3ddb7c3eccddc7d9aed6",
        "answer": "E) Liddle syndrome\n",
        "description": "E\tLiddle syndrome → Reabsorption defect in collecting tubules → metabolic alkalosis, ↓ aldosterone\n",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:13:12.576Z",
        "updatedAt": "2023-12-05T15:13:12.576Z"
      }
    ],
    "types": [
      "spot"
    ]
  },
  {
    "question_id": "656f3e267c3eccddc7d9aede",
    "answer_id": "656f3e417c3eccddc7d9aee3",
    "chicks": [
      {
        "_id": "656f3e417c3eccddc7d9aee0",
        "question_id": "656f3e267c3eccddc7d9aede",
        "answer": "A) Metabolic alkalosis",
        "description": "",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:14:09.824Z",
        "updatedAt": "2023-12-05T15:14:09.824Z"
      },
      {
        "_id": "656f3e417c3eccddc7d9aee1",
        "question_id": "656f3e267c3eccddc7d9aede",
        "answer": "B) Respiratory alkalosis",
        "description": "",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:14:09.824Z",
        "updatedAt": "2023-12-05T15:14:09.824Z"
      },
      {
        "_id": "656f3e417c3eccddc7d9aee2",
        "question_id": "656f3e267c3eccddc7d9aede",
        "answer": "C) Respiratory acidosis",
        "description": "",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:14:09.825Z",
        "updatedAt": "2023-12-05T15:14:09.825Z"
      },
      {
        "_id": "656f3e417c3eccddc7d9aee3",
        "question_id": "656f3e267c3eccddc7d9aede",
        "answer": "D) Metabolic acidosis",
        "description": "",
        "isTrue": true,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:14:09.825Z",
        "updatedAt": "2023-12-05T15:14:09.825Z"
      },
      {
        "_id": "656f3e417c3eccddc7d9aee4",
        "question_id": "656f3e267c3eccddc7d9aede",
        "answer": "E) Hyperchloremic metabolic acidosis",
        "description": "",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:14:09.825Z",
        "updatedAt": "2023-12-05T15:14:09.825Z"
      }
    ],
    "types": [
      "case"
    ]
  },
  {
    "question_id": "656f3e597c3eccddc7d9aee6",
    "answer_id": "656f3e747c3eccddc7d9aee8",
    "chicks": [
      {
        "_id": "656f3e747c3eccddc7d9aee8",
        "question_id": "656f3e597c3eccddc7d9aee6",
        "answer": "A) Metabolic alkalosis",
        "description": "",
        "isTrue": true,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:15:00.223Z",
        "updatedAt": "2023-12-05T15:15:00.223Z"
      },
      {
        "_id": "656f3e747c3eccddc7d9aee9",
        "question_id": "656f3e597c3eccddc7d9aee6",
        "answer": "B) Respiratory alkalosis",
        "description": "",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:15:00.223Z",
        "updatedAt": "2023-12-05T15:15:00.223Z"
      },
      {
        "_id": "656f3e747c3eccddc7d9aeea",
        "question_id": "656f3e597c3eccddc7d9aee6",
        "answer": "C) Respiratory acidosis",
        "description": "",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:15:00.224Z",
        "updatedAt": "2023-12-05T15:15:00.224Z"
      },
      {
        "_id": "656f3e747c3eccddc7d9aeeb",
        "question_id": "656f3e597c3eccddc7d9aee6",
        "answer": "D) Metabolic acidosis",
        "description": "",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:15:00.224Z",
        "updatedAt": "2023-12-05T15:15:00.224Z"
      },
      {
        "_id": "656f3e747c3eccddc7d9aeec",
        "question_id": "656f3e597c3eccddc7d9aee6",
        "answer": "E) Hyperchloremic metabolic acidosis",
        "description": "",
        "isTrue": false,
        "status": "active",
        "types": [
          "spot"
        ],
        "__v": 0,
        "createdAt": "2023-12-05T15:15:00.224Z",
        "updatedAt": "2023-12-05T15:15:00.224Z"
      }
    ],
    "types": [
      "board"
    ]
  }
]