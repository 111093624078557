import { Alert, Input, Textarea } from "@material-tailwind/react"
import React, { useEffect } from "react";
import { defaultQuestions, questionFormAtom } from "../../utils/atoms";
import { useAtomValue } from "jotai";
import ReCAPTCHA from "react-google-recaptcha";

// const env = require('dotenv')
type SurveyProps = {
  email: string,
  country: string,
  question1: number | null,
  question2: number | null,
  question3: number | null,
  priceQuestion: string,
  additionalFeatures: string
}



const Survey = () => {

  const questionForm = useAtomValue(questionFormAtom)
  const defaultquestions = useAtomValue(defaultQuestions)
  const [isAlertOpen, setIsAlertOpen] = React.useState('')
  const [captchaToken, setCaptchaToken] = React.useState(null)
  const [survey, setSurvey] = React.useState<SurveyProps>({
    email: '',
    country: '',
    question1: null,
    question2: null,
    question3: null,
    priceQuestion: '',
    additionalFeatures: ''
  })


  function fillAlert() {
    alert('Please fill all the fields!')
  }
  function handleEmail(event: any) {
    setSurvey({ ...survey, email: event.target.value })
  }
  function handleCountry(event: any) {
    setSurvey({ ...survey, country: event.target.value })
  }
  function handlePriceQuestion(event: any) {
    setSurvey({ ...survey, priceQuestion: event.target.value })
  }
  function handleAdditionalFeatures(event: any) {
    setSurvey({ ...survey, additionalFeatures: event.target.value })
  }
  function handleQuestion1(stars: any) {
    setSurvey({ ...survey, question1: stars })
  }
  function handleQuestion2(stars: any) {
    setSurvey({ ...survey, question2: stars })
  }
  function handleQuestion3(stars: any) {
    setSurvey({ ...survey, question3: stars })
  }

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.google.com/recaptcha/enterprise.js?render=6LecgzwpAAAAAECujRehq7-2SQ3dJa-GlacYMg1h";
    script.async = true;
    document.body.appendChild(script);

  }, [])
  // function onClick(e) {
  //   e.preventDefault();
  //   grecaptcha.enterprise.ready(async () => {
  //     const token = await grecaptcha.enterprise.execute('6LecgzwpAAAAAECujRehq7-2SQ3dJa-GlacYMg1h', { action: 'LOGIN' });
  //   });
  // }

  function onSubmit() {
    document.getElementById("demo-form")
  }
  function onChange(value: any) {
    // console.log("Captcha value:", value);
    setCaptchaToken(value)
  }

  function submit(event: any) {
    event.preventDefault()

    let scoreCount = 0
    for (let index = 0; index < defaultquestions?.length; index++) {
      const trueanswerid = defaultquestions[index].chicks.find((chick: any) => {
        return chick.isTrue ? chick._id : null
      })._id
      if (trueanswerid === questionForm[index].answer) {
        scoreCount += 1
      }
    }

    let body = {
      ...survey,
      answers: questionForm,
      score: scoreCount
    }

    if (
      survey.question1 === null ||
      survey.question2 === null ||
      survey.question3 === null
    ) {
      fillAlert()
    }


    fetch(process.env.REACT_APP_IP + 'survey/create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then(res => {
        if (!res.ok) throw Error('Email already exists!')
        return res.json()
      })
      .then(data => {
        if (data) {
          localStorage.setItem('questions', JSON.stringify([]))
          localStorage.setItem('wronganswers', JSON.stringify([]))
          localStorage.setItem('user', JSON.stringify(data))
          // window.location.href = `https://genius99.co/thank-you`
          window.open(`https://genius99.com/thank-you?score=${scoreCount}&questionlength=${defaultquestions.length}`, "_self")
        }
        else {
          alert('Survey not submitted!')
          setIsAlertOpen('Survey not submitted!')
        }
      })
      .catch(err => {
        alert(err.message)
      })

  }


  let disabledSubmit = `
  w-full rounded-lg py-3 px-6 text-center
  text-xs font-bold uppercase text-white    
  pointer-events-none   bg-gray-400
  border-b-4  border-gray-600 
   
  `
  let activeSubmit = `
  w-full rounded-lg py-3 px-6 text-center
  text-xs font-bold uppercase text-white  
  bg-blue-500  hover:bg-blue-700 border-b-4 border-[#6B6DCF] 
  text-white font-bold py-2 rounded-xl  
  bg-[#7F83F7]  hover:shadow-lg hover:shadow-gray-900/20 
  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] 
   
  `





  return (
    <>


      <div className="pt-12 mb-8 h-screen  flex flex-col flex-grow text-gray-700 h-max	   shadow-none   bg-clip-border overflow-y:auto">
        <h4 className="block font-sans text-2xl text-center antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
          Congratulations on completing the Genius99 Demo!
        </h4>
        <p className="   block mt-1 font-sans text-center antialiased font-normal leading-relaxed text-gray-700 max-w-lg  self-center  ">
          We'd love to hear your thoughts! To see your result, please take a moment to complete our short survey below.
        </p>




        <form onSubmit={submit} className="max-w-screen-lg mt-8 mb-10 pb-[100px] w-80 sm:w-96 self-center  ">
          <div className="flex flex-col gap-6 mb-1 ">

            <h6
              className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
              Your Email
            </h6>
            <div className="relative h-11 w-full min-w-[200px]">
              <input placeholder="name@mail.com" onChange={handleEmail} type="email" required
                className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
              <label
                className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
            </div>

            <h6
              className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
              Country
            </h6>
            <div className="relative h-11 w-full min-w-[200px]">
              <input type="text" placeholder="eg. United States" onChange={handleCountry} required
                className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
              <label
                className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
            </div>

            <h6
              className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
              How would you rate the overall quality of the content in the demo?
            </h6>
            <div className="relative h-11 w-full min-w-[200px]">
              <StarList onClick={handleQuestion1} />
            </div>

            <h6
              className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
              How likely are you to use the full version of our app once it becomes available?
            </h6>
            <div className="relative h-11 w-full min-w-[200px]">
              <StarList onClick={handleQuestion2} />
            </div>

            <h6
              // style={{ fontFamily: 'poppin-regular' }}
              className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
              How likely are you to recommend this app to your peers or colleagues?
            </h6>
            <div className="relative h-11 w-full min-w-[200px]">
              <StarList onClick={handleQuestion3} />
            </div>
            <h6
              // style={{ fontFamily: 'poppin-regular' }}
              className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
              What would you consider a fair price for the full version?
            </h6>
            <div className="relative h-11 w-full min-w-[200px]">
              <Input type="text" maxLength={50} placeholder="$30/month" crossOrigin={''} required onChange={handlePriceQuestion} />
            </div>
            <h6
              className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
              Are there any additional features or content you would like to see in the full version of the app?
            </h6>
            <div className="relative h-11 w-full min-w-[200px] mb-12 ">
              <Textarea maxLength={5000} label="Your Ideas" required onChange={handleAdditionalFeatures} />
            </div>
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
              onChange={onChange}
            />
            <button
              disabled={Boolean(!captchaToken)}
              className={Boolean(!captchaToken) ? disabledSubmit : activeSubmit}
              type="submit">
              Complete Survey
            </button>


          </div>
        </form>


      </div>
      <div style={{
        position: 'absolute',
        // bottom: 100,
        backgroundColor: '#F6F7FE',
        left: -10,
        // top: 0,
        width: '100%',
        height: '70%',

        marginRight: 2,
        marginTop: -20,
        background: '#F6F7FE',
        zIndex: -1,
        borderWidth: 1,
        alignSelf: 'center',
        // borderColor: 'red',
      }}
      />



      <Alert children={isAlertOpen} className="absolute top-10 right-10 w-fit" open={Boolean(isAlertOpen)} />
    </>
  )
}
export default Survey

const StarList = ({ onClick }: any) => {
  const [stars, setStars] = React.useState(0)
  const arr = [1, 2, 3, 4, 5]

  function selectStars(index: number) {
    setStars(index)
    onClick(index)

  }
  return (
    <div className="grid  overflow-x-scroll rounded-lg  lg:overflow-visible">
      <div className="inline-flex items-center">
        {arr.map((item) => {
          // let isColored = 
          return (
            <StarSvg index={item} onClick={selectStars} key={item}
              isColored={item <= stars}
            />
          )
        })}
      </div>
    </div>
  )
}

const StarSvg = ({ index, onClick, isColored }: any) => {
  function handleOnClick() {
    onClick(index)
  }
  let colorClassname = isColored ? 'text-yellow-700' : 'text-gray-400'
  return (
    <>
      <span onClick={handleOnClick} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
          fill="currentColor" className={`w-9 h-9 ${colorClassname} cursor-pointer`}>
          <path fillRule="evenodd"
            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
            clipRule="evenodd"></path>
        </svg>
      </span>
    </>

  )
}

