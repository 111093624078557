import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react"



const GetQuestions = () => {
  const [data, setData] = useState<any>([])
  let header = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token') ?? ''
    },
  }
  useEffect(() => {
    fetch(process.env.REACT_APP_IP + 'questions', header)
      .then(res => res.json())
      .then(data => { setData(data) })
  }, [])

  return (
    <div>
      {data.map((item: any, index: number) => {
        const isLast = index === data.length - 1;
        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
        return (
          <div key={index} >
            <tr >
              <td className={classes}>
                <Typography variant="small" color="white" style={{ fontWeight: 'bold' }} className="font-normal" >
                  {item?.question}
                </Typography>
              </td>
              <td className={classes}>
                <Typography variant="small" color="gray" className="font-normal" >
                  {item?._id}
                </Typography>
              </td>
            </tr>

          </div>
        )
      })}
    </div>
  )
}

export default GetQuestions