
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Home from "./screens/Home/Home";
import './routerStyles.css'
import FormPage from "./screens/Form/Form";
import AddQuestion from "./screens/AddQuestion/AddQuestion";
import Summary from "./screens/Summary/Summary";
import Survey from "./screens/Survey/Survey";
import Login from "./screens/Backoffice/Login";
import Dashboard from "./screens/Backoffice/Dashboard";


const AppRouter = () => {
  return (
    <div className="">
      <Router>
        <div className="">
          {/* <Header /> */}
          <div className=""  >
            <AllRoutes />
          </div>
          {/* <Footer /> */}
        </div>

      </Router>
    </div >
  )
}
export default AppRouter;

const AllRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/form/:page" element={<FormPage />} />
      <Route path="/summary" element={<Summary />} />
      <Route path="/survey" element={<Survey />} />
      {/* <Route path="/form/2" element={<Home />} /> */}
      <Route path="*" element={<div>page not found</div>} />
      {/* <Route path="/genius/addquestion" element={<AddQuestion />} /> */}
      <Route path="/genius/backoffice" element={<Login />} />
      <Route path="/genius/backoffice/login" element={<Login />} />
      <Route path="/genius/backoffice/dashboard" element={<Dashboard />} />
      <Route path="/genius/backoffice/:param" element={<Dashboard />} />
    </Routes>
  )
}



