import { useAtom } from "jotai";
import React from "react";
import { questionFormAtom, wrongAnswersAtom } from "../../utils/atoms";
import { Button } from "@material-tailwind/react";



const Home: React.FC = () => {
  const [questionForm, setQuestionForm] = useAtom(questionFormAtom)
  const [wrongAnswers, setWrongAnswers] = useAtom(wrongAnswersAtom)
  React.useEffect(() => {
    setQuestionForm([])
    setWrongAnswers([])
  }, [])
  return (
    <div className="  h-full  grid  place-items-center flex-col mb-20 ">
      <div className="max-w-screen-md ">
        <div className="grid place-items-center mb-6" >
          <img src={require("./../../assets/geniuslogo.png")} className="w-40 place-self-center" />
        </div>
        <h1 className="text-xl mb-8 text-center"  >
          Get a sneak peek into how Genius99 transforms your USMLE preparation!
        </h1>
        <h1 className="text-md mb-2 text-center" >
          In this demo, you'll experience a selection of Spot Questions, Real-World Cases, and Board-Style Questions, showcasing our unique learning approach.
        </h1>
        <h1 className="text-md mb-8 text-center"  >
          The Demo test will take 10 mins approximately.
        </h1>
        <div className="  grid  place-items-center   ">
          <Button
            variant="outlined"
            className={`bg-blue-500 hover:bg-blue-700 border-b-4 border-[#6B6DCF]   text-white font-bold py-2   rounded-xl w-1/2     `}
            onClick={() => { window.location.href = "/form/0" }}
            style={{
              fontFamily: 'poppin-medium',
              background: '#7F83F7'
            }}
          >
            Start
          </Button>
        </div>
      </div>
    </div >
  )
}
export default Home;
