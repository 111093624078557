import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";



const questions = [
  {
    _id: 1,
    question: 'Which of the following nerves is most commonly injured in fibular neck fractures?',
    correctAnswer: '1',
    answer: null,
    type: 'spotquestion',
    checkbox: [
      { _id: '1', text: 'fibularis communis', chick: 'a' },
      { _id: '2', text: 'suralis lateralis', chick: 'b' },
      { _id: '3', text: 'saphenous', chick: 'c' },
      { _id: '4', text: 'ischiadicus', chick: 'd' },
    ]
  },
  {
    _id: 2,
    question: 'almost u re in second question',
    correctAnswer: '2',
    answer: null,
    type: 'spotquestion',
    checkbox: [
      { _id: '5', text: 'communis', chick: 'a' },
      { _id: '6', text: 'lateralis', chick: 'b' },
      { _id: '7', text: 'fibularis', chick: 'c' },
      { _id: '8', text: 'suralis', chick: 'd' },
    ]
  },
  {
    _id: 3,
    question: 'thirddd question',
    correctAnswer: '3',
    answer: null,
    type: 'casequestion',
    checkbox: [
      { _id: '9', text: 'alfa', chick: 'a' },
      { _id: '10', text: 'beta', chick: 'b' },
      { _id: '11', text: 'sigma', chick: 'c' },
      { _id: '12', text: 'teta', chick: 'd' },
    ]
  },
  {
    _id: 4,
    question: 'forthhh question dskmgklds dgklsmkglds klgmdsfklgm klfmdslgm lkdfml kmgfdlk ldsmfsm kdsmk dmsk mkpdsmp mksdm kdsmkmkdsmkgdsmklgdsmk gkdsklgsdklgndsklgndskln kldng mlkfdm lkfdmlkdfmlkdfmlkfmdlk mlkdfmlkdfm lmfdklgmfdlkmglfdkm glkdfm lkgfdm mfgdmkl',
    correctAnswer: '4',
    answer: null,
    type: 'casequestion',
    checkbox: [
      { _id: '13', text: 'hard', chick: 'a' },
      { _id: '14', text: 'easy', chick: 'b' },
      { _id: '15', text: 'tricky', chick: 'c' },
      { _id: '16', text: 'failed', chick: 'd' },
    ]
  },

]




export const questionFormAtom = atomWithStorage<any>('questions', [])
export const defaultQuestions = atomWithStorage<any>('defaultquestions', [])
export const wrongAnswersAtom = atomWithStorage<any>('wronganswers', [])
export const answerCheckAtom = atom<any>(null)