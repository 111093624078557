import React, { useEffect } from 'react';
import './App.css';
import AppRouter from './Router';
import { ThemeProvider } from '@material-tailwind/react';
import { useAtomValue } from 'jotai';
import { questionFormAtom, wrongAnswersAtom } from './utils/atoms';
import BottomDrawer from './components/BottomDrawer/BottomDrawer';
import "./assets/fonts/Poppins-Black.ttf"
import "./assets/fonts/Poppins-Bold.ttf"
import "./assets/fonts/Poppins-ExtraBold.ttf"
import "./assets/fonts/Poppins-Light.ttf"
import "./assets/fonts/Poppins-Medium.ttf"
import "./assets/fonts/Poppins-Regular.ttf"
import "./assets/fonts/Poppins-SemiBold.ttf"

function App() {
  const [color, setColor] = React.useState('red');
  const [colorOpacity, setColorOpacity] = React.useState(100);

  const Preloader = () => {
    useAtomValue(questionFormAtom) // Trigger the "onMount" function that will load the data from the store
    useAtomValue(wrongAnswersAtom) // Trigger the "onMount" function that will load the data from the store
    return null
  }
  return (
    <div style={{ fontFamily: 'poppin-regular', background: '#F6F7FE', }}

      className={`
      font-poppins
      bg-blue-100 
      h-screen 
      w-screen 
      flex 
      items-center
      justify-center
     
    `}>
      <ThemeProvider>
        <Preloader />
        <AppRouter />
      </ThemeProvider>
      <BottomDrawer open={true} />
    </div>
  );
}

export default App;