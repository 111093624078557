import { Button, Checkbox, Textarea } from "@material-tailwind/react";
import React, { useEffect } from "react";


type answerProps = {
  question_id?: string,
  answer?: string,
  description?: string,
  isTrue?: boolean,
}

const AddQuestion = () => {

  const [addedQuestion, setAddedQuestion] = React.useState<any>()
  const [answerList, setAnswerList] = React.useState<answerProps[]>([
    // { question_id: addedQuestion?._id, answer: '', description: '', }
  ])
  const [formData, setFormData] = React.useState({
    question: '',
    answer: '',
    type: 'spot',
  })
  useEffect(() => {
    console.log('addedQuestion', addedQuestion);
  }, [addedQuestion])
  useEffect(() => {
    console.log('answerList', answerList);
  }, [answerList])
  useEffect(() => {
    console.log('formData', formData);
  }, [formData])
  async function handleSubmit(event: any) {
    try {
      let data = JSON.stringify(formData)
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token') || ''
        },
        body: data
      };
      fetch(process.env.REACT_APP_IP + 'question/create', requestOptions)
        .then(response => response.json())
        .then(data => {
          setAddedQuestion(data);
          setAnswerList([{ question_id: data?._id, answer: '', description: '', isTrue: false }])
        })
    } catch (error) {
      setAnswerList([])
    }
  }
  function handleChangeQuestion(text: any) {
    setFormData({ ...formData, question: text.target.value })
  }
  function handleChangeType(params: any) {
    setFormData({ ...formData, type: params.target.value })
  }
  function handleNewAnswer() {
    setAnswerList([...answerList, { question_id: addedQuestion?._id, answer: '', description: '', isTrue: false }])
  }
  function handleChangeTrueAnswer(index: number) {
    let newAnswerList = [...answerList];
    newAnswerList[index].isTrue = !newAnswerList[index].isTrue
    setAnswerList(newAnswerList)
  }
  function handleChangeAnswer(event: any, index: number) {
    let newAnswerList = [...answerList];
    newAnswerList[index].answer = event.target.value
    setAnswerList(newAnswerList)
  }
  function handleChangeAnswerDescription(event: any, index: number) {
    let newAnswerList = [...answerList];
    newAnswerList[index].description = event.target.value
    setAnswerList(newAnswerList)
  }



  async function onSubmitAnswer() {
    try {
      let data = JSON.stringify(answerList)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data
      };
      let response = await fetch(process.env.REACT_APP_IP + 'chicks/create', requestOptions)
      setAnswerList([])
      setAddedQuestion(null)

    } catch (error) {

    }

  }

  return (
    <>
      <div className="w-screen flex" >

        <div className="w-1/3 ml-4" >

          <form>

            <Textarea className="h-96" size="lg" label="Question" onChange={(event) => handleChangeQuestion(event)} />
            <label>
              Select type of question
              <select className="w-full mb-8  h-94 border-4 border-blue-200"
                name="dsfds"
                onChange={item => handleChangeType(item)}   >
                <option value="spot">spot</option>
                <option value="case">case</option>
                <option value="board">board</option>
              </select>
            </label>
            <a>
              <Button variant="gradient" disabled={Boolean(addedQuestion)}
                onClick={handleSubmit} >submit</Button>
            </a>
          </form>
        </div>
        {(addedQuestion) &&

          <div className="w-2/3"  >
            {answerList?.map((item: any, index: number) => {
              return (
                <div key={index} className="flex mb-2 ml-2">
                  <Textarea label="Answer" error onChange={(event) => handleChangeAnswer(event, index)} />
                  <Textarea label="Answer Description" error onChange={(event) => handleChangeAnswerDescription(event, index)} className="pl-2 ml-2" />
                  <Checkbox crossOrigin={'true'}
                    onClick={() => handleChangeTrueAnswer(index)}
                    color="red"
                    label={'is TRUE answer?'}
                  />
                </div>
              )
            })}
            <Button className="ml-2" onClick={handleNewAnswer} color='amber' children={'Add new answer +++'} disabled={Boolean(!addedQuestion)} />
            <Button className="ml-2" onClick={onSubmitAnswer} color='blue-gray' children={'Submit Answers and reset'} disabled={Boolean(!addedQuestion)} />
          </div>

        }
      </div >

      <form>

      </form>
    </>
  )
}

export default AddQuestion;