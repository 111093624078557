import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";



const downloadFile = (myData: any) => {
  // const { myData } = this.state; // I am assuming that "this.state.myData"
  // is an object and I wrote it to file as
  // json

  // create file in browser
  const fileName = "Users " + new Date().toLocaleDateString();
  const json = JSON.stringify(myData, null, 2);
  const blob = new Blob([json], { type: "application/json" });
  const href = URL.createObjectURL(blob);

  // create "a" HTLM element with href to file
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}


const AllUsers = () => {
  const [data, setData] = useState<any>([])

  async function fetchUsers() {
    try {

      let Users = await fetch(process.env.REACT_APP_IP + 'backoffice/newsletterusers', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token') ?? ''
        },
      }).then(res => res.json())
      if (data) {
        setData(Users);
      }
      else {
        setData([])
      }
    } catch (error) {
      setData([])
    }
  }
  useEffect(() => {
    fetchUsers()
  }, [])
  function donwloadAsJson() {
    downloadFile(data)
  }

  return (
    <>
      <div>
        <Button color="light-blue" ripple={true} className="mb-8  w-[100px]" onClick={() => fetchUsers()} >Refresh</Button>
        <Button color="light-blue" ripple={true} className="mb-8 ml-2 w-[190px]" onClick={donwloadAsJson} >Download Json All</Button>
        {/* <Button color="green" ripple={true} className="mb-8 ml-2 w-[180px]" onClick={() => fetchUsers()} >Download csv All</Button> */}
      </div>
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
              Email
            </th>
            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
              Country
            </th>
            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
              Score
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 && data?.map(({ email, country, score }: any, index: number) => {
            const isLast = index === data.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
            return (
              <tr key={index}>
                <td className={classes}>
                  <Typography variant="small" color="white" style={{ fontWeight: 'bold' }} className="font-normal" >
                    {email}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal" >
                    {country}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal" >
                    {score}
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  )
}

export default AllUsers;