import { Button, Input } from "@material-tailwind/react";
import { useState } from "react";




const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function handleUsername(event: any) {
    setUsername(event.target.value);
  }
  function handlePassword(event: any) {
    setPassword(event.target.value);
  }

  async function fetchLogin() {
    try {
      let response = await fetch(process.env.REACT_APP_IP + 'backoffice/login', {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password })
      }).then(res => res.json())
      if (response?.token) {
        localStorage.setItem('token', `Bearer ${response.token}`);
        window.location.href = '/genius/backoffice/dashboard';
        alert('login successful');
      } else {
        alert('login failed');
      }

    } catch (err) {
      alert('invalid credentials');
    }

  }

  function handleSubmit(event: any) {
    event.preventDefault();
    if (username && password) {
      fetchLogin()
    } else {
      alert('fill all fields');
    }
  }

  return (
    <>
      <div className="text-center  p-10   grid border ">
        Genius Backoffice
        <form className="mt-10" onSubmit={handleSubmit} >
          <Input variant="standard" onChange={(text) => handleUsername(text)} label="Username" crossOrigin={''} className="mb-2" size={'lg'} />
          <Input variant="standard" onChange={(text) => handlePassword(text)} label="Password" crossOrigin={''} type="password" />
          <Button color="blue" ripple={true} className="mt-8" type="submit" >Login</Button>
        </form>
      </div>

    </>
  )
}
export default Login;