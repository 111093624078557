import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import AllUsers from "./AllUsers";
import AddQuestion from "../AddQuestion/AddQuestion";
import GetQuestions from "./pages/GetQuestions";


type screenProps = 'dashboard' | 'addquestion' | 'addbook' | 'addblock' | unknown

const Dashboard = () => {
  const [screen, setScreen] = useState<screenProps>('dashboard')

  useEffect(() => {
    let isThereToken = localStorage.getItem('token');
    if (!isThereToken) {
      window.location.href = '/genius/backoffice/login';
    }
    setScreen(window.location.href.split('/').pop())
  }, [])


  return (
    <>
      <div className="flex  w-screen h-screen  ">
        <SideBar />
        <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-black p-6  ">
          {sidebarItems.map((item, index) => {
            if (item.url === screen) {
              return item.template
            }
          })}
        </div>
      </div>
    </>
  )

}
export default Dashboard;




const sidebarItems = [
  {
    label: 'See All Users',
    template: <AllUsers />,
    url: 'dashboard'
  },
  {
    label: 'Add Question',
    template: <AddQuestion />,
    url: 'addquestion'
  },
  {
    label: 'Get Questions',
    template: <GetQuestions />,
    url: 'getquestions'
  },
  {
    label: 'Log Out',
    template: 'dashboard',
    url: 'logout'
  },
]
const SideBar = () => {

  let prefix = 'genius/backoffice/dashboard'
  function logOut() {
    localStorage.removeItem('token');
    window.location.href = '/genius/backoffice/login';
  }
  function handlePage(params: string) {
    if (params === 'logout') {
      logOut();
      return;
    }
    window.location.href = `/genius/backoffice/${params}`;
  }
  return (
    <div className="relative flex flex-col bg-clip-border rounded-xl bg-gray-200 
    text-gray-700 h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
      <div className="mb-2 p-4">
        <h5 className="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-gray-900">
          Genius Demo Dashboard
        </h5>
      </div>
      <nav className="flex flex-col gap-1 min-w-[240px] p-2 font-sans text-base font-normal text-gray-700">
        {
          sidebarItems.map((item, index) => {

            return (
              <>
                <div onClick={() => handlePage(item.url)} role="button" tabIndex={index} className="flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-blue-50 hover:bg-opacity-80 focus:bg-blue-50 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80 hover:text-blue-900 focus:text-blue-900 active:text-blue-900 outline-none">
                  <div className="grid place-items-center mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5">
                      <path fillRule="evenodd" d="M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.04 16.5l.5-1.5h6.42l.5 1.5H8.29zm7.46-12a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zm-3 2.25a.75.75 0 00-1.5 0v3.75a.75.75 0 001.5 0V9zm-3 2.25a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5z" clipRule="evenodd"></path>
                    </svg>
                  </div>
                  {item.label}
                </div>
              </>
            )
          })
        }
      </nav>
    </div>
  )
}