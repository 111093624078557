import { useAtom } from "jotai"
import { defaultQuestions, questionFormAtom, wrongAnswersAtom } from "../../utils/atoms"
import { Typography } from "@material-tailwind/react"
import { useEffect } from "react"





const Summary = () => {
  const [questionForm, setQuestionForm] = useAtom(questionFormAtom)
  const [wrongAnswers, setWrongAnswers] = useAtom(wrongAnswersAtom)
  const [defaultQ, setDefaultQuestions] = useAtom(defaultQuestions)

  async function updateUserAnswers() {
    try {
      let user: any = localStorage.getItem('user')
      user = JSON.parse(user || '{}')
      fetch(process.env.REACT_APP_IP + 'survey/update?_id=' + user?._id, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(questionForm)
      }).then(res => res.json())


    } catch (err) {

    }
  }
  useEffect(() => {
    if (questionForm.length > 0) {
      updateUserAnswers()
    }
  }, [])
  return (
    <>
      {/* {wrongAnswers.map((question: any, index: number) => <div>
        {wrongAnswers[index].answer}
      </div>)} */}

      <Typography>
        Your score is {questionForm.length * wrongAnswers.length} out of {questionForm.length}
      </Typography>
    </>
  )
}
export default Summary